import { useLocation } from "@reach/router"
import pick from "lodash/pick"
import queryString from "query-string"
import { useEffect } from "react"
import { useCookies } from "react-cookie"

const CAMPAIGN_KEYS = [
  "utm_content",
  "utm_term",
  "utm_campaign",
  "utm_medium",
  "utm_source",
  "gclid",
  "hsa_grp",
  "hsa_src",
  "hsa_ad",
  "hsa_mt",
  "hsa_tgt",
  "hsa_kw",
  "hsa_net",
  "hsa_cam",
  "hsa_ver",
  "hsa_acc",
  "MSCKLID",
  "fbclid",
]

export function useStoreCampaignVariablesInCookies() {
  const location = useLocation()
  const searchParameters = queryString.parse(location.search)
  const [, setCookie] = useCookies([])

  useEffect(() => {
    CAMPAIGN_KEYS.forEach(
      key => searchParameters[key] && setCookie(key, searchParameters[key], { path: "/" })
    )
  }, [searchParameters, setCookie])
}

export function useCampaignCookies() {
  const [cookies] = useCookies()
  return pick(cookies, CAMPAIGN_KEYS)
}
