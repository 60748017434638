export default {
  "pt-BR": {
    contato: "/solicitar-orcamento",
    servicos: "/servicos",
    imprensa: "/x-apps-na-midia",
    vagas: "/trabalhe-conosco",
    portfolio: "/portfolio",
    blog: "/blog",
    contrateSquads: "/contrate-squads",
    home: "/",
    termosDeServico: "/termos-de-servico",
    termosDePrivacidade: "/termos-de-privacidade",
    obrigado: "/obrigado",
  },
  en: {
    contato: "/request-budget",
    servicos: "/services",
    imprensa: "/x-apps-in-the-media",
    vagas: "/jobs",
    portfolio: "/portfolio",
    blog: "/blog",
    home: "/",
    contrateSquads: "/contrate-squads",
    termosDeServico: "/acceptable-use-policy",
    termosDePrivacidade: "/privacy-policy",
    obrigado: "/thank-you",
  },
}[process.env.GATSBY_LANGUAGE]
