import React from "react"
import routes from "@utils/routes"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import "@x-apps-projetos/design-system/dist/esm/index.css"

function getImageHeadTags(image, siteUrl) {
  const defaultSquareImage = `${siteUrl}/assets/img/openGraphCoverSmall.png`
  const defaultLargeImage = `${siteUrl}/assets/img/openGraphCoverLarge.png`
  const imageUrl = `${siteUrl}${image}`
  const imageTags = image
    ? [
      <meta key="image" name="image" content={imageUrl} />,
      <meta key="og:image" property="og:image" content={imageUrl} />,
      <meta key="twitter:image" name="twitter:image" content={imageUrl} />,
      <meta
        key="og:image:type"
        property="og:image:type"
        content="image/jpeg"
      />,
    ]
    : [
      <meta key="image" name="image" content={defaultSquareImage} />,
      <meta
        key="twitter:image"
        name="twitter:image"
        content={defaultSquareImage}
      />,
      <meta key="og:image" property="og:image" content={defaultLargeImage} />,
      <meta
        key="og:image:type"
        property="og:image:type"
        content="image/png"
      />,
      <meta key="og:image:width" property="og:image:width" content="1200" />,
      <meta key="og:image:height" property="og:image:height" content="630" />,
      <meta
        key="og:image1"
        property="og:image"
        content={defaultSquareImage}
      />,
      <meta
        key="og:image:type1"
        property="og:image:type"
        content="image/png"
      />,
      <meta key="og:image:width1" property="og:image:width" content="400" />,
      <meta
        key="og:image:height1"
        property="og:image:height"
        content="400"
      />,
    ]

  return imageTags
}

const SEO = ({ title, description, keywords, article, image }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    titleTemplate,
    defaultKeywords,
    defaultDescription,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    titleTemplate: titleTemplate,
    title: title || defaultTitle,
    keywords: keywords || defaultKeywords,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
  }

  const completeTitle = `X-Apps - ${seo.title}`
  const imageTags = getImageHeadTags(image, siteUrl)
  const isHome = pathname === routes.home
  return (
    <Helmet
      htmlAttributes={{
        lang: process.env.GATSBY_LANGUAGE,
      }}
      title={seo.title}
      titleTemplate={seo.titleTemplate}
    >
      <meta
        http-equiv="Content-Security-Policy"
        content="upgrade-insecure-requests"
      />
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta property="og:title" content={completeTitle} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      {imageTags}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={completeTitle} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:creator" content={twitterUsername} />
      {article && <meta property="og:type" content="article" />}
      {isHome && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            url: siteUrl,
            potentialAction: {
              "@type": "SearchAction",
              target: `${siteUrl}${routes.blog}?q={search_term_string}`,
              "query-input": "required name=search_term_string",
            },
          })}
        </script>
      )}
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultKeywords: keywords
        siteUrl
        twitterUsername
      }
    }
  }
`
